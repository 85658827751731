import React from 'react'
import { UseCasesWrapper, UseCasesText, UseCasesIcons } from './UseCases.css'
import { CardUseCase, useKinetiq } from 'kinetiq'

export const UseCases = ({ title, cards }) => {
  const kinetiq = useKinetiq()
  return (
    <div css={UseCasesWrapper(kinetiq.base)}>
      <div css={UseCasesText(kinetiq.base)}>
        <h1>{title}</h1>
      </div>
      <div css={UseCasesIcons(kinetiq.base)}>
        {cards.map((card, index) => (
          <CardUseCase key={index} {...card} />
        ))}
      </div>
    </div>
  )
}
