import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const FeaturesWrapper = (base, background) => css`
  position: relative;
  z-index: 2;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 128px 0px;

  & > div:nth-of-type(1) {
    position: absolute;
    width: 100vw;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${background};
  }

  .featuresContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }

  .featuresLeft {
    & > div {
      margin-bottom: 24px;
    }
  }

  .featuresRight {
    & > div {
      margin-bottom: 24px;
    }
  }

  ${base.MQ.phone} {
    padding: 48px 0px;

    .featuresContainer {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }

    .featuresLeft {
      & > div {
        margin-bottom: 16px;
      }
    }

    .featuresRight {
      & > div {
        margin-bottom: 16px;
      }
    }
  }
`

export const FeaturesText = (base) => css`
  max-width: 856px;
  margin-bottom: 96px;
  text-align: center;

  /* title */
  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.largest,
      color: base.colorNeutral._1000,
      margin: '0',
      padding: '0',
    })}
  }

  /* text */
  & > h2 {
    ${js2css({
      ...base.typeEditorial.common,
      ...base.typeEditorial.larger,
      color: base.colorTint._500,
      margin: '8px 0px 0px 0px',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    margin-bottom: 24px;
    text-align: center;

    /* title */
    & > h1 {
      ${js2css({
        ...base.typeTitle.medium,
      })}
    }

    /* text */
    & > h2 {
      ${js2css({
        ...base.typeEditorial.medium,
      })}
    }
  }
`
