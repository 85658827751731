import { React, useEffect } from 'react'
import { FeaturesWrapper, FeaturesText } from './Features.css'
import { CardIcon, useKinetiq } from 'kinetiq'

export const Features = ({ title, text, background, cardsLeft, cardsRight }) => {
  const kinetiq = useKinetiq()

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let initPos
      let leftHeight
      let rightHeight
      let maxMove
      let startScroll

      const getOffsetTop = (element) => {
        let offsetTop = 0
        while (element) {
          offsetTop += element.offsetTop
          element = element.offsetParent
        }
        return offsetTop
      }

      const updateVariables = (targetLeft, targetRight) => {
        initPos = getOffsetTop(targetRight)
        leftHeight = targetLeft.offsetHeight
        rightHeight = targetRight.offsetHeight + 24
        if (window.innerWidth > 425) {
          maxMove = leftHeight - rightHeight
        } else {
          maxMove = 0
        }
        startScroll = initPos - window.innerHeight / 2
      }

      const handleResize = (e) => {
        const targetLeft = document.querySelector('.featuresLeft')
        const targetRight = document.querySelector('.featuresRight')
        updateVariables(targetLeft, targetRight)
      }

      const handleScroll = (e) => {
        const targetLeft = document.querySelector('.featuresLeft')
        const targetRight = document.querySelector('.featuresRight')
        let moveBy = 0

        if (!initPos) {
          updateVariables(targetLeft, targetRight)
        }

        let scrolled = window.pageYOffset

        if (scrolled > startScroll) {
          moveBy = (scrolled - startScroll) / 2

          if (moveBy > maxMove) {
            moveBy = maxMove
          }
        }

        targetRight.style.transform = `translateY(${moveBy}px)`
      }

      window.addEventListener('resize', handleResize)
      window.addEventListener('scroll', handleScroll)

      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  return (
    <div css={FeaturesWrapper(kinetiq.base, background)}>
      <div></div>
      <div css={FeaturesText(kinetiq.base)}>
        <h1>{title}</h1>
        <h2>{text}</h2>
      </div>
      <div className='featuresContainer'>
        <div className='featuresLeft'>
          {cardsLeft.map((card) => (
            <CardIcon key={card.title} {...card} />
          ))}
        </div>
        <div>
          <div className='featuresRight'>
            {cardsRight.map((card) => (
              <CardIcon key={card.title} {...card} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
