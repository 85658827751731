import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const PromisesWrapper = (base, background) => css`
  position: relative;
  z-index: 2;
  padding: 96px 24px 80px 24px;

  & > div:nth-of-type(1) {
    position: absolute;
    width: 100vw;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    z-index: -1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: ${background};
  }

  & > div:nth-of-type(3) {
    & > div {
      margin-bottom: 80px;
    }
  }

  ${base.MQ.phone} {
    padding: 48px 0px 0px 0px;

    & > div:nth-of-type(3) {
      & > div {
        margin-bottom: 0px;
        padding-bottom: 48px;
      }
    }
  }
`

export const PromisesText = (base) => css`
  text-align: center;
  margin-bottom: 72px;

  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.largest,
      color: base.colorNeutral._1000,
      margin: '0',
      padding: '0',
    })}
  }

  & > h2 {
    ${js2css({
      ...base.typeEditorial.common,
      ...base.typeEditorial.larger,
      color: base.colorTint._500,
      margin: '8px 0px 0px 0px',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    margin-bottom: 32px;

    & > h1 {
      ${js2css({
        ...base.typeTitle.medium,
      })}
    }

    & > h2 {
      ${js2css({
        ...base.typeEditorial.medium,
      })}
    }
  }
`
