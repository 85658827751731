import React, { useState } from 'react'
import { emailValidator } from 'email-validator'
import { useModal } from 'react-modal-hook'

import {
  SignupModalWrapper,
  SignupModalContainer,
  CloseButton,
  CloseButtonWrapper,
  SignupHeader,
  SignupFormElementsContainer,
  MessageContainer,
  SubmitContainer,
} from './SignupModal.css'
import { Button, TextInput, TextAreaInput, useKinetiq } from 'kinetiq'

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address.'

const defaults = {
  fullName: '',
  email: '',
  company: '',
  content: '',
}

const errorDefaults = {
  fullName: '',
  email: '',
  company: '',
  content: '',
}

export const SignupModal = () => {
  const kinetiq = useKinetiq()

  const [show, setShow] = useState(true)
  const [message, setMessage] = useState('')
  const [values, setValues] = useState(defaults)
  const [errors, setErrors] = useState(errorDefaults)
  const [result, setResult] = useState(false)

  const onSubmitHandler = async (e) => {
    e.preventDefault()

    if (typeof window !== `undefined`) {
      const path = window.location.protocol + '//' + window.location.host
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      }
      const response = await fetch(`${path}/.netlify/functions/contactFormSendGrid`, options)
        .then((res) => res)
        .catch((error) => error)

      setResult(response)

      if (response.status === 200) {
        console.log(response)
        setValues(defaults)
        setMessage('Thank you for your submission!')
        setShow(true)
      } else {
        console.log('An error occured while submitting the form!')
        setMessage('An error occured while submitting the form!')
        setShow(true)
      }
    }
  }

  const onChangeOther = ({ target }) => {
    let newValues = { ...values, [target.name]: target.value }
    setValues({ ...newValues })
    setResult(false)
    setShow(false)
  }

  const onChangeEmail = ({ target }) => {
    let newValues = { ...values, [target.name]: target.value }
    let newErrors = { ...errors }
    const valid = emailValidator.validate(target.value)
    if (!valid) {
      setErrors({ ...newErrors, email: INVALID_EMAIL_MESSAGE })
    } else {
      setErrors({ ...newErrors, email: '' })
    }
    setValues({ ...newValues })
    setResult(false)
    setShow(false)
  }

  const [showSignupModal, hideSignupModal] = useModal(
    ({ in: isOpen, onExited: afterClose }) => {
      const props = {
        isOpen,
        afterClose,
        onClose: hideSignupModal,
        onBackgroundClick: hideSignupModal,
        onEscapeKeydown: hideSignupModal,
        allowScroll: false,
      }

      return (
        <SignupModalWrapper {...props} base={kinetiq.base}>
          <div css={CloseButtonWrapper}>
            <div css={CloseButton(kinetiq.base)} onClick={hideSignupModal}></div>
          </div>
          <div css={SignupModalContainer(kinetiq.base)}>
            <div css={SignupHeader(kinetiq.base)}>
              <h1>Beta Signup</h1>
              <h2>Be the first to get access to Sol and Starship during the private beta.</h2>
            </div>
            <form onSubmit={onSubmitHandler} id='SignupForm'>
              <div css={SignupFormElementsContainer(kinetiq.base)}>
                <div>
                  <TextInput
                    type='text'
                    name='fullName'
                    error={errors.fullName}
                    label='Full Name'
                    value={values.fullName}
                    onChangeHandler={onChangeOther}
                  />
                  <TextInput
                    type='email'
                    name='email'
                    error={errors.email}
                    label='Email Address'
                    value={values.email}
                    onChangeHandler={onChangeEmail}
                  />
                  <TextInput
                    type='text'
                    name='company'
                    error={errors.company}
                    label='Company'
                    value={values.company}
                    onChangeHandler={onChangeOther}
                  />
                  <div>
                    <h2>Which products are you interested in?</h2>
                    <div>
                      <div>Checkbox 1</div>
                      <div>Checkbox 2</div>
                    </div>
                  </div>
                </div>
                <div>
                  <TextAreaInput
                    name='content'
                    error={errors.content}
                    label='Tell us a little about your team...'
                    value={values.content}
                    onChangeHandler={onChangeOther}
                  />
                </div>
              </div>
              <div css={MessageContainer(kinetiq.base, show, result)}>{message}</div>
              <div css={SubmitContainer(kinetiq.base)}>
                <div>
                  <Button
                    text='Submit'
                    disabled={
                      Object.values(values).includes('') ||
                      Object.values(errors).some((x) => x !== '')
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
            </form>
          </div>
        </SignupModalWrapper>
      )
    },
  )

  return <Button text='Request Beta Access' onClickHandler={showSignupModal}></Button>
}
