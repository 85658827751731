import { css } from '@emotion/core'
import { js2css } from 'kinetiq'
import Modal from '../Modal/Modal'

export const SignupModalWrapper = Modal.styled`
    width: 1032px;
    min-height: 600px;
    max-height: 85vh;

    background-color: ${({ base }) => base.colorNeutral._200};
    position: relative;
    border-radius: 12px;

    box-sizing: border-box;
    padding: 18px;

    ${({ base }) => base.MQ.phone} {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        max-height: 100vh;
        border-radius: 0px;

        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }
`

export const CloseButtonWrapper = css`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 18px;
`

export const CloseButton = (base) => css`
  background-color: ${base.colorTint._100};
  border-radius: 100vh;
  width: 20px;
  height: 20px;
`

export const SignupModalContainer = (base) => css`
  margin: 0px 32px 32px 32px;

  ${base.MQ.phone} {
    margin: 0px 8px 8px 8px;
  }
`

export const SignupHeader = (base) => css`
  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.large,
      color: base.colorNeutral._1000,
      margin: '0',
      padding: '0',
    })}
  }

  & > h2 {
    ${js2css({
      ...base.typeEditorial.common,
      ...base.typeEditorial.medium,
      color: base.colorTint._500,
      margin: '8px 0px 56px 0px',
      padding: '0',
    })}
  }

  ${base.MQ.phone} {
    & > h1 {
      ${js2css({
        ...base.typeTitle.small,
      })}
    }

    & > h2 {
      ${js2css({
        ...base.typeEditorial.smaller,
        margin: '8px 0px 26px 0px',
      })}
    }
  }
`

export const SignupFormElementsContainer = (base) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 26px;

  /* left side */
  & > div:nth-of-type(1) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 26px;

    /* checkbox section */
    & > div:nth-of-type(4) {
      /* text */
      & > h2 {
        ${js2css({
          ...base.typeData.common,
          ...base.typeData.smaller,
          color: base.colorTertiary._500,
          margin: '0px 0px 9px 16px',
          padding: '0',
        })}
      }

      /* checkbox container */
      & > div:nth-of-type(1) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 26px;
      }
    }
  }

  /* right side */
  & > div:nth-of-type(2) {
  }

  ${base.MQ.phone} {
    grid-template-columns: 1fr;
  }
`

export const MessageContainer = (base, show, result) => css`
  ${js2css({ ...base.typeEditorial.common, ...base.typeEditorial.large })}

  color: ${
    result
      ? result.status === 200
        ? `${base.colorTertiary._500}`
        : `${base.colorSecondary._500}`
      : `${base.colorSecondary._500}`
  };
  display: ${show ? 'static' : 'none'};

  text-align: right;
  margin-bottom: 24px;
`

export const SubmitContainer = (base) => css`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  ${base.MQ.phone} {
    display: block;
    width: 100%;
  }
`
