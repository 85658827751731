import React from 'react'
import { CoverBillboardLeft, FeatureChecklist } from 'kinetiq'
import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'
import { Features } from '../components/Features'
import { UseCases } from '../components/UseCases'
import { Promises } from '../components/Promises'
import { SignupModal } from '../components/SignupModal'
import { SVGImage } from '../components/SVGImage'
import { PageContent } from '../components/PageContent'

const SolPage = () => (
  <Layout>
    <SEO title='Sol: Artificial Intelligence Accelerated Infrastructure' />
    <CoverBillboardLeft
      image=''
      title='Sol'
      tagline='The cloud designed for artificial intelligence'
      text='Rapidly scalable cloud infrastructure to help you serve, manage, and scale artificial intelligence applications. Improve performance and reduce costs.'
      rightSide={
        <FeatureChecklist
          title='Serve, Manage, Scale'
          items={[
            { icon: <SVGImage variant='IconCheck' />, text: 'Frictionless Setup' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Automatic Scaling' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Faster Releases' },
            { icon: <SVGImage variant='IconCheck' />, text: 'Lower Costs' },
          ]}
          button={<SignupModal />}
        />
      }
    />
    <PageContent>
      <Features
        background='none'
        title='Powerful and Scalable'
        text='Customize your cloud strategy with flexible compute, storage, and networking options.'
        cardsLeft={[
          {
            image: <SVGImage variant='IconSolBlazing' />,
            title: 'Blazing Fast Hardware',
            text:
              "Our hardware stack is optimized for artificial intelligence, whether it is training, inference, or data exploration - we've got you covered.",
          },
          {
            image: <SVGImage variant='IconSolScalable' />,
            title: 'Scalable Storage',
            text:
              'We give you the flexibility to add storage on-demand and easy access to your data from anywhere. Pay only for the data you store or transfer out.',
          },
          {
            image: <SVGImage variant='IconSolAdvanced' />,
            title: 'Advanced Monitoring',
            text:
              "Receive notifications when things aren't going right. We provide system-level performance in the same place you manage the infrastructure, free of charge.",
          },
        ]}
        cardsRight={[
          {
            image: <SVGImage variant='IconSolConfigure' />,
            title: 'Flexible Hardware Configurations',
            text:
              'Select high performance compute instances, single-tenant bare-metal resources, or create a virtual private cloud.',
          },
          {
            image: <SVGImage variant='IconSolRich' />,
            title: 'Feature Rich API',
            text:
              'Bring automation to the forefront of your strategy. Our API enables you to build custom applications and manage your resources in a simple, programmatic way.',
          },
        ]}
      />
      <Promises
        background='#01070D'
        title='Our Promise'
        heroes={[
          {
            image: <SVGImage variant='HeroSolPredictable' />,
            title: 'Predictable Pricing',
            text:
              "Say goodbye to complex pricing. It's simple - you pay for exactly what you use without a complicated fee structure.",
          },
          {
            image: <SVGImage variant='HeroSolHigh' />,
            title: 'High Availability',
            text:
              'Flexible redundancy levels to support any use case. We take care of all your mission-critical applications.',
            alternate: true,
          },
          {
            image: <SVGImage variant='HeroSolSecurity' />,
            title: 'Security and Compliance',
            text:
              'Cloud security at Qoor is the highest priority. We keep your data safe and ensure end-to-end compliance.',
          },
          {
            image: <SVGImage variant='HeroSolWorld' />,
            title: 'World-Class Support',
            text:
              '24/7 technical support with additional services available for premium subscribers. Customer satisfaction is our magnetic north.',
            alternate: true,
          },
        ]}
      />
      <UseCases
        title='Use Cases'
        cards={[
          { image: <SVGImage variant='UseCasePrivate' />, text: 'Virtual Private Cloud' },
          { image: <SVGImage variant='UseCaseCloud' />, text: 'Cloud Bursting' },
          { image: <SVGImage variant='UseCaseEdge' />, text: 'Edge Computing' },
          { image: <SVGImage variant='UseCaseTraining' />, text: 'Training' },
          { image: <SVGImage variant='UseCaseInference' />, text: 'Inference' },
          { image: <SVGImage variant='UseCaseData' />, text: 'Data Science' },
        ]}
      />
    </PageContent>
  </Layout>
)

export default SolPage
