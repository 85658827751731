import { css } from '@emotion/core'
import { js2css } from 'kinetiq'

export const UseCasesWrapper = (base) => css`
  max-width: 820px;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  padding: 128px 0px;

  ${base.MQ.phone} {
    display: static;
    max-width: 100%;
    margin: 0;
    padding: 48px 0px;
  }
`

export const UseCasesText = (base) => css`
  margin-bottom: 64px;

  & > h1 {
    ${js2css({
      ...base.typeTitle.common,
      ...base.typeTitle.largest,
      color: base.colorNeutral._1000,
      margin: '0',
      padding: '0',
    })}
    text-align: center;
  }

  ${base.MQ.phone} {
    margin-bottom: 24px;

    & > h1 {
      ${js2css({
        ...base.typeTitle.medium,
      })}
    }
  }
`

export const UseCasesIcons = (base) => css`
  display: flex;
  flex-flow: row wrap;
  margin: 0 -64px;
  align-content: center;
  justify-content: center;

  & > div {
    max-width: 188px;
    margin: 0px 64px 60px 64px;
  }

  ${base.MQ.phone} {
    display: grid;
    align-self: flex-start;
    grid-gap: 16px;
    margin: 0;

    & > div {
      width: 100%;
      max-width: 100%;
      margin: 0px;
    }
  }
`
