import React from 'react'
import { PromisesWrapper, PromisesText } from './Promises.css'
import { HeroSmall, useKinetiq } from 'kinetiq'

export const Promises = ({ background, title, text, heroes }) => {
  const kinetiq = useKinetiq()
  return (
    <div css={PromisesWrapper(kinetiq.base, background)}>
      <div></div>
      <div css={PromisesText(kinetiq.base)}>
        <h1>{title}</h1>
        <h2>{text}</h2>
      </div>
      <div>
        {heroes.map((hero, index) => (
          <HeroSmall key={index} {...hero} />
        ))}
      </div>
    </div>
  )
}
